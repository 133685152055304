<template>
  <div class="merlends">
    <el-table
      border
      :data="tableData1"
      style="width: 100%"
      max-height="650"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column align="center" label="编号" type="index" width="70" />
      <el-table-column label="提交时间" width="200" prop="create_time" />

      <el-table-column label="供应商名称" width="250" prop="name" />
      <el-table-column label="审核时间" width="200" prop="update_time" />
      <el-table-column label="审核人">
        <template slot-scope="{ row }">
          <span style="margin-right: 10px">{{ row.operatorname }}</span>
          <span>{{ row.operator }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" prop="audit_status_name" />
      <el-table-column
        label="驳回原因"
        width="300"
        prop="remark"
        show-overflow-tooltip
      />
    </el-table>
    <!-- 分页 -->
    <pagination
      v-show="pageTotal > 0"
      :total="pageTotal"
      :page.sync="reviewApi.page"
      :limit.sync="reviewApi.perPage"
      @pagination="GETreviewList"
    />
    <div class="operation">
      <el-button type="primary" @click="goback">返回</el-button>
    </div>
  </div>
</template>

<script>
// import { loglist } from "@/api/userqualiy.js";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      tableData1: [],
      reviewApi: {
        page: 1,
        perPage: 10,
      },
      reviewData: {},
      pageTotal: 1,
      type: "",
    };
  },
  filters: {
    shenstate(v) {
      switch (v) {
        case 0:
          return "审核失败";
        case 1:
          return "审核成功";
        case 2:
          return "审核中";
      }
    },
  },
  created() {
    this.type = this.$route.query.type;
    this.GETreviewList();
  },
  methods: {
    ...mapActions(["getlogList"]),
    async GETreviewList() {
      var from = { ...this.reviewApi };
      from.business_id = this.$route.query.id;
      let res = await this.getlogList(from);
      this.tableData1 = res.data.data;
      this.pageTotal = res.data.total;
    },
    goback() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.paging {
  margin-top: 10px;
  text-align: right;
}
.operation {
  text-align: center;
  span {
    padding: 10px 20px;
    border-radius: 4px;
    margin: 0 10px;
    color: #fff;
    font-size: 12px;
    background-color: #06b7ae;
  }
}
</style>
